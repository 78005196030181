<template>
  <div class="checkbox-container" @click="setStopPropagation">
    <input
      v-model="proxyChecked"
      type="checkbox"
      :disabled="disable"
      :value="value"
      :id="`checkbox-${this.value}-${localId}`"
    />
    <label :for="`checkbox-${this.value}-${localId}`">
      <div class="checkbox-field">
        <i class="bi bi-check checkmark" />
      </div>
      <slot name="label" />
      <span class="body6" v-if="label">{{ label }}</span>
    </label>
  </div>
</template>

<script>
import 'bootstrap-icons/font/bootstrap-icons.css'

export default {
  name: 'MCheckbox',
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    disable: {
      type: Boolean,
      default: false,
    },
    value: {
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    stopPropagation: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      localId: this.id ? this.id : Math.random().toString(),
    }
  },
  methods: {
    setStopPropagation(event) {
      if (this.stopPropagation) {
        event.stopPropagation();
      }
    }
  },
  computed: {
    proxyChecked: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.checkbox-container {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  input[type='checkbox'] {
    width: 1px;
    height: 1px;
    position: absolute;
    top: 0;
    right: 0;

    &:checked {
      &:disabled + label {
        opacity: 0.5;
        cursor: not-allowed;

        &:before {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      + label {
        .checkbox-field {
          background: #1f6bc5;
          border: #1f6bc5;
        }
        .checkmark {
          opacity: 1; 
          transform: scale(1);
        }
      }
    }

    &:disabled + label {
      opacity: 0.5;
      cursor: not-allowed;
      &:before {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  label {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #5a7676;
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 100%;

    .checkbox-field {
      position: relative;
      flex: 0 0 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #b5bdbe;
      transition: background-color 0.5s, border-color 0.5s ease-in-out;
    }

    .checkmark {
      display: flex;
      opacity: 0;
      position: absolute;
      left: -47%;
      margin-left: 40%;
      bottom: -33%;
      margin-bottom: 40%;
      width: 100%;
      height: 100%;
      font-weight: 700;
      color: #ffffff;
      font-size: 1.4em;
      transition: transform 0.5s, opacity 0.5s;
    }
  }
}
</style>