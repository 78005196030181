import components from "./components";
import pages from "./pages";

export default {
    ...pages,
    ...components,
    "landing": {
        "about_company": "Компания туралы",
        "services": "Мәзір",
        "investment": "Инвестициялар",
        "career": "Мансап",
        "news": "Жаңалықтар",
        "TrustContract": "TrustContract",

        "invest_into_the_fastest_grow_up_startup": "Орталық Азияның ең жылдам өсіп келе жатқан стартапына <span>инвестиция салыңыз</span>",

        "invest_into_with_big_future": "Үлкен болашағы бар стартапқа инвестиция салыңыз!",
        "invest_into_description": "“2020 жылы пікірлер мен ұсыныстар платформасы ретінде жолын бастаған <a href=\"#\">TrustMe</a> бүгінде Қазақстанда заңды және жеке тұлғалар пайдаланатын танымал алаңқайға айналды. 2021 жылдың желтоқсанында біз онлайн-келісімшарт жасау қызметін — <a href=\"#\">TrustContract</a> іске қостық”.",
        "founder_and_ceo": "Чингиз Даулетбаев <span>— TrustMe Сенім Экожүйесінің негізін қалаушы және бас директоры.</span>",

        "read_more": "Көбірек білу",

        "eco_system": "TrustMe Экожүйесі",
        "eco_system_trust": "TrustMe сенім экожүйесі <span>TrustMe</span>",
        "get_more_about_trustme": "TrustMe туралы көбірек біліңіз",
        "mission": "Миссия",
        "aim": "Мақсат",
        "technology": "Технологиялар",
        "product": "Өнім",
        "have_questions": "Сұрақтарыңыз бар ма?",

        "make_world_better_with_technologies": "Технологиялар мен инновациялар арқылы бұл әлемді жақсырақ етіңіз",
        "become_the_first_in_central_asia": "Орталық Азиядан шыққан алғашқы едәуір компания болу",
        "smart_contract_blockchain_ai": "SmartContract, Blockchain, AI және басқа да технологиялар",
        "service_online_sign_applications": "<a href=\"#\">TrustContract</a> - Blockchain технологиясында SmartContracts құру және электрондық келісімшарттар жасау қызметі",
        "leave_a_request": "Өтінім қалдырыңыз және біз сізбен инвестициялау мүмкіндіктері туралы сөйлесу үшін хабарласамыз",

        "our_achievements": "Біздің жетістіктеріміз",
        "entered_into_top_10_sturtups": "АҚШ-тың Силикон алқабындағы <span>ТОП-10 стартапқа</span> енді",
        "after_finish_hero_training": "Hero Training аяқталғаннан кейін, TrustMe 35 елден келген 100-ден астам стартаптар арасында ең инновациялық және инвестиция тартымды шешім ретінде танылып, ең үздік ондыққа енді.",
        "entered_into_top_3_best_startups": "MasterCard және Astana Finance Days нұсқасы бойынша <span>үздік үш стартаптың</span> қатарына енді",
        "top_6_startups_in_central_asia_bank_program": "Азия даму банкының бағдарламасындағы <span>алты үздік стартаптың</span> бірі",
        "entered_into_the_top_15_startups": "2023 жылы Орталық Азиядан шыққан <span>ең үздік 15 стартаптың</span> қатарына енді",
        "best_blockchain_project_in_kz": "Қазақстандағы <span>ең үздік blockchain жобасы</span>",
        "abroad_office_in_uzbekistan": "Өзбекстанда шетелдік кеңсе ашты",
        "top_10_sturtups_in_digital_bridge": "2023 жылы Digital Bridge-де Орталық Азияның <span>ТОП-10 стартапының</span> қатарына енді",

        "the_fastest_and_easiest_solution": "Бұл ең жылдам және ең оңай E-Sign шешімі",
        "five_types_of_use": "Қолданудың 5 әдісі",
        "messenger": "Мессенджер",
        "sms_code": "SMS код",
        "ecp": "ЭЦҚ",
        "biometric": "Биометрия",
        "egov_mobile": "Egov mobile",

        "using_technology": "Технологияларды пайдалану",
        "smart_contract": "Smart Contract",
        "blockchain": "Blockchain",
        "qr_sign": "QR қол қою",

        "we_can_be_to": "Біз үшін",
        "b2b": "B2B",
        "b2c": "B2C",
        "b2g": "B2G",

        "rule": "Заңдылық",
        "online_sign_application_accepted_in_countries": "Құжаттарды электронды түрде қол қою әлемнің 100-ден астам елінде заңды",
        "get_more_about_trustcontract": "TrustContract туралы көбірек біліңіз",

        "quantity_of_clients": "Клиенттер саны",
        "signed_agreements": "Қол қойылған келісімшарттар",
        "earned_all_the_time": "Барлық уақыттағы табыс",

        "cases_trust_contract": "TrustContract кейстері",
        "review_our_clients": "Біздің клиенттердің пікірлері",

        "client_cases_header": {
            "1": "Bramf",
            "2": "«Болашақ» бағдарламасы",
            "3": "Metry.kz",
            "4": "EduWay",
            "5": "Quantum STEM School",
            "6": "Gid Travel",
            "7": "Riviera мектебі",
            "8": "Jana Post",
            "9": "KT&G"
        },
        "client_cases_review": {
            "1": "Жиһаз цехы",
            "2": "Степендиаттардан онлайн өтінімдер мен құжаттарды қабылдау",
            "3": "Жылжымайтын мүлік агенттігі",
            "4": "Оқушыларды білім беру курстары мен мектептерге оқыту үшін онлайн платформа",
            "5": "Халықаралық деңгейдегі мектеп",
            "6": "Туристік компания",
            "7": "Жеке мектеп",
            "8": "Логистикалық компания",
            "9": "Темекі компаниясы"
        },
        "client_cases_description": {
            "1": "TrustContract келісімшарттарды жасасуды жеңілдетеді, мәмілелерге кететін уақытты қысқартып, сатуларды арттырады. Мысалы, Bramf жиһаз цехы TrustContract арқылы табысын 15% арттырды",
            "2": "2024 жылдың қаңтарынан бастап стипендиаттар тәжірибеден өту келісімшарттарын онлайн режимде, құжаттарды кеңсеге әкелместен жасай алады. ЭЦҚ қолданылуы арқылы кепілдік берушілерден өкілеттік қажеттілігі жойылады.",
            "3": "TrustContract келісімшарттарды қашықтан жасасуды жеңілдетеді, бұл риелторларға жаңа нарықтарды ашуға мүмкіндік береді. Мысалы, Metry.kz енді Қазақстанның әртүрлі қалаларынан келген клиенттермен сәтті жұмыс істей алады",
            "4": "Dostyq School білім беру орталығының 33 филиалында TrustContract сәтті интеграцияланды. Енді ата-аналар мектеппен келісімшартқа сол күні қол қоя алады.",
            "5": "Деректер базаларымен интеграция арқылы көптеген келісімшарттарға қол қоюды енгіздік, бұл бизнес-процестерді оңтайландыруға және келісімшарттармен жұмысты автоматтандыруға мүмкіндік берді.",
            "6": "TrustContract арқылы жетілдірулер клиенттермен мәмілелер уақытын қысқартып, қызып тұрған туристік турлардың сатылымын арттырып, конверсияны жоғарылатты.",
            "7": "TrustContract арқылы ата-аналармен келісімшартқа қол қою уақытын 2-3 күннен 1 сағатқа дейін қысқарттық. Ата-аналар сервистің ыңғайлылығын және жылдамдығын жоғары бағалады, бұл маңызды процестерді тиімді етеді.",
            "8": "Кеден процедураларын жеңілдетіп, тауар декларацияларын цифрландыруды жеңілдеттік, бұл клиенттердің уақыты мен ресурстарын үнемдейді.",
            "9": "TrustContract жеткізу шығындарын қысқартып, атқарылған жұмыстар актілерін қол қою процесін жылдамдатуға мүмкіндік берді, бұл клиенттермен тиімді және тез арада өзара әрекеттесуді қамтамасыз етеді."
        },

        "our_clients": "Біздің клиенттер",
        "smi_talks_about_us": "БАҚ біз туралы айтады",
        "read": "Оқу",

        "capital_and_investors": "Капитал және Инвесторлар",
        "six_thousand_invest": "$600 000 инвестиция",
        "get_right_now": "қазіргі уақытта тартылды",
        "from_2_to_15": "<span>$2,5</span> млн-нан <span>$15 млн</span>-ға дейін",
        "grow_up_from_21_to_23": "компанияның бағасының 2021 жылдан 2023 жылға дейінгі өсуі",
        "we_are_in_believe_to_20_investors": "Бізге әртүрлі елдерден келген 20-дан астам инвестор сенеді",
        "investors_card_name": {
            "1": "Марлен Шохитбаев",
            "2": "Эльдар Толеш",
            "3": "Ерик Шыныбеков",
            "4": "Габит Кемелов"
        },
        "investors_card_country": {
            "1": "Берлин, Германия",
            "2": "Дубай, БАӘ",
            "3": "Берлин, Германия",
            "4": "Цюрих, Швейцария"
        },
        "investors_card_major": {
            "1": "Scope Ratings, Директор",
            "2": "Жылжымайтын мүлік инвесторы және періште инвесторы",
            "3": "Қор нарығы мен жеке капитал инвесторы",
            "4": "Blockchain маманы"
        },
        "investors_card_description": {
            "1": "IT, Телекоммуникация, Жылжымайтын мүлік, Мұнай және Газ",
            "2": "Unique Property Broker сатулардың ассоциациялық директоры",
            "3": "Инвестициялық клубтың негізін қалаушы",
            "4": "Six Digital Exchange жоғары бизнес талдаушысы"
        },
        "investors_card_place": {
            "1": "Freie Universitat Berlin, MBA",
            "2": "Freie Universitat Berlin, MBA",
            "3": "SBS Swiss Business School, MBA",
            "4": "Цюрих университеті"
        },

        "plans_into_next_year": "2024 жылға арналған жоспарлар",
        "x16": "x16",
        "how_many_times_we_need_to_improve": "2024 жылы осынша есе өсіре арқылы",
        "by_upgrade_cost_of_company": "компанияның құнын <span>$150 млн</span> дейін көтеру",
        "new_markets": "Жаңа нарықтар",
        "new_products": "Жаңа өнімдер",
        "high_demand": "Жоғары сұраныс",
        "task": "Міндет",
        "countries": "Түркия, Сауд Арабиясы, АҚШ және басқа елдер",
        "description_of_products": "TrustMe AI, Big Data және Data Science",
        "despite_the_competition": "DocuSign, AdobeSign сияқты бәсекелестерге қарамастан",
        "scale_around_the_world": "Әлем бойынша кеңейіп, келісімшарттарды қол қою бойынша көшбасшы болу",

        "become_part_of_trustme": "TrustMe бөлігі болыңыз",
        "investors_we_invite": "Құрметті инвесторлар, біз сіздерді шақырамыз",
        "$100": "$100.000",
        "$400": "$400.000",
        "three_business_angels": "Үш бизнес періштесін",
        "venture_fund": "Венчурлық қор",
        "estimated_at_20_millions": "$20 млн бағалаумен",
        "estimated_at_15_millions": "$15 млн бағалаумен",

        "get_up_to_invest": "Инвестициялауға үлгеріңіз!",
        "ceo_personally_contact_you": "Өтінім қалдырыңыз және CEO жеке өзі сізбен хабарласады",
        "submit_your_application": "Өтінімді қалдыру",
        "contact_info_of_head": "Негізін қалаушының байланыс ақпараты",
        "take_an_opportunity_to_share": "Мүмкіндікті бөлісіңіз - басқаларға жіберіңіз!",

        "contact_headers": {
            "1": "+7 (777) 518-22-00",
            "2": "daukaz",
            "3": "trustme.kz",
            "4": "ceo.trustme{'@'}gmail.com",
            "5": "{'@'}qazaqeli",
            "6": "{'@'}trustme.kz",
            "7": "dauletbayev",
            "8": "dauletbayev"
        },

        "make_the_world_better": "Бұл әлемді жақсартайық!",
        "contacts": "Байланыс",
        "politic_of_confidence": "Құпиялылық саясаты",
        "terms_of_use": "Пайдалану шарттары",
        "public_offer": "Жалпыға арналған ұсыныс",
        "address": "Астана қ., Қабанбай батыр даңғылы, 49",
        "email": "ceo.trustme{'@'}gmail.com",
        "sale_department": "Сату бөлімі:+7 (707)-111-09-50",
        "tech_support": "Техникалық қолдау: +7 (707)-412-84-12",
        "all_supported": "© 2024 TrustMe Limited. Барлық құқықтар қорғалған.",

        "invest_into_fast_sturtup": "Орталық Азияның ең жылдам өсіп келе жатқан стартапына инвестиция салыңыз",

        "be_an_investor": "Инвестор болыңыз"
    }
}
