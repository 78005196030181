export default {
    "businessSolution":"Бизнес үшін экожүйелік IT шешімдер құрамыз",
    "efficiency":"Сіздің компанияңыздағы тиімділік пен ашықтықты арттыру үшін <br /> бизнес-процестерді цифрландыру",
    "takeSolution":"Шешімді таңдау",
    "optimization":"TrustMe — Бизнесті оңтайландыру бойынша IT-шешім ",
    "optimizationTitle1": "Үздік стартап",
    "optimizationSubTitle1": "Hero Training АҚШ нұсқасы бойынша",
    "optimizationTitle2":"Blockchain Startup Awards",
    "optimizationSubTitle2": "«Кремний алқабының сыйлық иегері",
    "optimizationTitle3":"ТОP-10 стартап",
    "optimizationSubTitle3": "Орталық Азия Digital Bridge 2023",
    "optimizationTitle4":"Орталық Азияның үздік стартаптарының қатарында",
    "optimizationSubTitle4": "Google For Startups сәйкес",
    "ourProducts":" Біздің өнімдер ",
    "productDescription1": "TrustContract-ең жоғары өнімділік үшін смарт-келісім-шарттар мен ыңғайлы үлгілерді пайдаланатын SMS арқылы шарттарға жедел және қауіпсіз қол қою сервисі.",
    "productButton1": "Өту",
    "productDescription2": "Құжаттарды құруды, қол қоюды және сақтауды оңтайландыра отырып, бастапқы есепке алу құжаттамасын автоматтандыруға және цифрландыруға арналған сервис.",
    "productButton2": "Жақында",
    "productDescription3": "Кадрлық процестерді автоматтандыруға, өтініштерді беруді және бекітуді жеңілдетуге және HR-бөлімнің тиімділігін арттыруға арналған сервис.",
    "productButton3": "Жақында",
    "customerOpinions":"Клиенттер біз жайлы",
    "ourVision" : "Біздің көзқарасымыз",
    "visionLabel1": "Миссиямыз",
    "visionDescription1": "Технология мен инновация арқылы әлемді жақсарту",
    "visionLabel2": "Мақсатымыз",
    "visionDescription2": "Орталық Азиядағы алғашқы жалғыз мүйіз болу",
    "visionLabel3": "Құндылықтарымыз",
    "visionDescription3": "Біздің қызметіміздің барлық аспектілерінде сенімділік пен ашықтықты ұстану",
    "team" : "Командамыз",
    "teamDescription": "Біздің жұмысымыздың негізінің барлық аспектілерінде тазалық, сенім және ашықтық жатыр.<br />Бізге қосыл!",
    "seeVacancy": "Командаға қосылу",
    "tag1": "тиімділік",
    "tag2": "нәтижелік",
    "tag3": "клиентке бағдарлану",
    "tag4": "профессионалдық",
    "tag5": "тиімділік",
    "tag6": "нәтижелік",
    "tag7": "клиентке бағдарлану",
    "tag8": "профессионалдық",
    "news": "Жаңалықтар",
    "newsLabel1": "White Paper Seed Инвестицияның I кезеңі үшін",
    "newsDescription1": "Сәлеметсіз бе, достар! Бүгін мен сізге мүмкін емес нәрсені жасаған адам туралы айтқым келеді-TrustMe компаниясының негізін қалаушы және бас директоры, Шыңғыс Дәулетбаев.",
    "newsLabel2": "Trust Contract 2024 Жаңартулары",
    "newsDescription2": "Құрметті достар, біз сізге TrustContract сервисінің керемет жаңартуларын дайындадық. Бұл материалда біз оларды сипаттауға тырысамыз.",
    "newsLabel3": "TrustContract туралы жиі қойылатын ТОП-5 сұрақ",
    "newsDescription3": "Бұл материалда біз сіздерге TrustContract туралы пайдаланушылардың ең жиі қойылатын 5 сұрақтарды дайындадық.",
    "seeNews": "Басқаларды қарау",
    "trustTechnologies": "Сенуге болатын технологиялар",
    "technologiesDescription":"Біздің технологиялар кәсіби мамандармен кәсіби мамандардың <br /> қажеттіліктеріне барлық қамқорлықпен жасалған.",
    "contactUs":"Бізбен хабарласу",
    "time":"{time} минут",
    }