import components from "./components";
import pages from "./pages";

export default {
    ...pages,
    ...components,
    "landing": {
        "about_company": "О компании",
        "services": "Сервисы",
        "investment": "Инвестиции",
        "career": "Карьера",
        "news": "Новости",
        "TrustContract": "TrustContract",

        "invest_into_the_fastest_grow_up_startup": "Инвестируйте <span>в самый быстрорастущий</span> стартап Центральной Азии",

        "invest_into_with_big_future": "Инвестируйте в стартап с большим будущим!",
        "invest_into_description": "“Начав свой путь в 2020 году как платформа для отзывов и рекомендаций, <a href=\"#\">TrustMe</a> на сегодняшний день стала популярной площадкой, используемой как юридическими, так и физическими лицами в Казахстане. В декабре 2021 года мы запустили онлайн-сервис подписания договоров —\n" +
            "              <a href=\"#\">TrustContract</a>”.",
        "founder_and_ceo": "Чингиз Даулетбаев <span>— основатель и CEO Экосистемы Доверия TrustMe.</span>",

        "read_more": "Узнать больше",

        "eco_system": "Экосистема TrustMe",
        "eco_system_trust": "Экосистема доверия <span>TrustMe</span>",
        "get_more_about_trustme": "Узнать больше о TrustMe",
        "mission": "Миссия",
        "aim": "Цель",
        "technology": "Технологии",
        "product": "Продукт",
        "have_questions": "Остались вопросы?",

        "make_world_better_with_technologies": "Сделать этот мир лучше при помощи технологий и инноваций",
        "become_the_first_in_central_asia": "Cтать первым единорогом из Центральной Азии",
        "smart_contract_blockchain_ai": "SmartContract, Blockchain, AI и другие технологии",
        "service_online_sign_applications":"<a href=\"#\">TrustContract</a> - сервис электронного подписания договоров и создания SmartContracts на технологии Blockchain",
        "leave_a_request":"Оставьте заявку и мы свяжемся с вами, чтобы рассказать о возможностях инвестирования",

        "our_achievements": "Наши достижения",
        "entered_into_top_10_sturtups": "Вошли <span>в TОП-10 стартапов</span> в Кремниевой долине, США",
        "after_finish_hero_training": "По завершению Hero Training, TrustMe была признана наиболее инновационным и инвестиционно привлекательным решением, войдя в десятку лучших среди более чем 100 стартапов из 35 стран мира.",
        "entered_into_top_3_best_startups": "Вошли <span>в ТОП-3 лучших стартапа</span> по версии MasterCard а Astana Finance Days",
        "top_6_startups_in_central_asia_bank_program": "<span>Среди 6 лучших стартапов</span> в программе Азиатского банка развития",
        "entered_into_the_top_15_startups": "Прошли <span>в 15 лучших</span> из Центральной Азии в 2023 году",
        "best_blockchain_project_in_kz": "<span>Лучший blockchain проект</span> Казахстана",
        "abroad_office_in_uzbekistan": "Открыли зарубежный офис в Узбекистане",
        "top_10_sturtups_in_digital_bridge": "<span>В ТОП-10 стартапов</span> Центральной Азии на Digital Bridge 2023",

        "the_fastest_and_easiest_solution": "Это самое быстрое и легкое E-Sign решение",
        "five_types_of_use": "5 способов подписания",
        "messenger": "Мессенджер",
        "sms_code": "SMS код",
        "ecp": "ЭЦП",
        "biometric": "Биометрия",
        "egov_mobile": "Egov mobile",

        "using_technology": "Используем технологии",
        "smart_contract": "Smart Contract",
        "blockchain": "Blockchain",
        "qr_sign": "QR подписания",

        "we_can_be_to": "Мы подходим для",
        "b2b": "B2B",
        "b2c": "B2C",
        "b2g": "B2G",

        "rule": "Законность",
        "online_sign_application_accepted_in_countries": "Электронное подписание документов является законным в более чем 100 странах мира",
        "get_more_about_trustcontract": "Узнать больше о TrustContract",

        "quantity_of_clients": "Количество клиентов",
        "signed_agreements": "Подписанных договоров",
        "earned_all_the_time": "Доходы за все время",

        "cases_trust_contract": "Кейсы TrustContract",
        "review_our_clients": "Отзывы наших клиентов",

        "client_cases_header": {
            "1":"Bramf",
            "2":"Программа «Болашак»",
            "3":"Metry.kz",
            "4":"EduWay",
            "5":"Quantum STEM School",
            "6":"Gid Travel",
            "7":"Riviera school",
            "8":"Jana Post",
            "9":"KT&G",
        },
        "client_cases_review": {
            "1":"Мебельный цех",
            "2":"Онлайн прием заявок и сопутствующих документов от стипендиатов",
            "3":"Агентство недвижимости",
            "4":"Онлайн платформа для обучения учеников образовательных курсов и школ",
            "5":"Школа международного уровня",
            "6":"Туристическая компания",
            "7":"Частная школа",
            "8":"Логистическая компания",
            "9":"Табачная компания",
        },
        "client_cases_description": {
            "1":"TrustContract упрощает заключение договоров, сокращая время на сделки и способствуя увеличению продаж. Например, мебельный цех Bramf с TrustContract увеличил доходы на 15%",
            "2":"С января 2024 г. стипендианты могут заключать договора на стажировки онлайн, без посещения офиса для предоставления документов. Процесс упрощен за счет использования ЭЦП, что исключает необходимость в доверенности от гарантов.",
            "3":"TrustContract упрощает заключение договоров удаленно, что открывает новые рынки для риелторов. Например, Metry.kz теперь может успешно работать с клиентами из разных городов Казахстана",
            "4":"Успешно интегрировали TrustContract в 33 филиала образовательного центра Dostyq School. Теперь родители могут подписать договор со школой день в день.",
            "5":"Внедрили массовое подписание договоров через интеграцию с внутренними базами данных, что позволило оптимизировать бизнес-процессы и автоматизировать работу с договорами.",
            "6":"Улучшения с помощью TrustContract привели к сокращению времени сделок с клиентами, увеличили количество продаж горящих туров и повысили конверсию.",
            "7":"Благодаря TrustContract сократили время подписания договоров с родителями от 2-3 дней до 1 часа. Родители высоко оценили удобство и скорость сервиса, который делает важные процессы более эффективными.",
            "8":"Упростили таможенные процедуры и цифровизировали подачу товарных деклараций, сэкономив время и ресурсы клиентов.",
            "9":"TrustContract позволил сократить расходы на доставку и ускорить процесс подписания актов выполненных работ, обеспечивая более оперативное и эффективное взаимодействие с клиентами.",
        },

        "our_clients": "Наши клиенты",
        "smi_talks_about_us": "О нас говорят в СМИ",
        "read": "Прочитать",

        "capital_and_investors": "Капитал и Инвесторы",
        "six_thousand_invest": "$600 000 инвестиций",
        "get_right_now": "привлекли на данный момент",
        "from_2_to_15": "с <span>$2,5</span> млн до <span>$15 млн</span>",
        "grow_up_from_21_to_23": "рост оценки компании с 2021 по 2023 годы",
        "we_are_in_believe_to_20_investors": "Нам доверяют более 20 инвесторов из разных стран",
        "investors_card_name": {
            "1":"Марлен Шохитбаев",
            "2":"Эльдар Толеш",
            "3":"Ерик Шыныбеков",
            "4":"Габит Кемелов",
        },
        "investors_card_country": {
            "1":"Berlin, Germany",
            "2":"Dubai, UAE",
            "3":"Berlin, Germany",
            "4":"Zurich, Switzerland",
        },
        "investors_card_major": {
            "1":"Scope Ratings, Director",
            "2":"Real Estate Investor & Angel Investor",
            "3":"Stock market and private equity investor",
            "4":"Blockchain specialist",
        },
        "investors_card_description": {
            "1":"IT, Telecom, Real estate, Oil & Gas",
            "2":"Associate Director of Sales Unique Property Broker",
            "3":"Founder of Investment Club",
            "4":"Senior Business Analyst in Six Digital Exchange",
        },
        "investors_card_place": {
            "1":"Freie Universitat Berlin, MBA",
            "2":"Freie Universitat Berlin, MBA",
            "3":"SBS Swiss Business School, MBA",
            "4":"University of Zurich",
        },

        "plans_into_next_year": "Планы на 2024 год",
        "x16": "x16",
        "how_many_times_we_need_to_improve": "Во столько раз мы должны вырасти в 2024 году,",
        "by_upgrade_cost_of_company": "увеличив стоимость компании до <span>$150 млн</span>",
        "new_markets": "Новые рынки",
        "new_products": "Новые продукты",
        "high_demand": "Высокий спрос",
        "task": "Задача",
        "countries": "Турция, Саудовская Аравия, США и другие страны",
        "description_of_products": "TrustMe AI, Big Data и Data Science",
        "despite_the_competition": "несмотря на конкуренцию (DocuSign, AdobeSign)",
        "scale_around_the_world": "Масштабироваться по миру, став лидером по подписанию договоров",

        "become_part_of_trustme": "Станьте частью ",
        "investors_we_invite": "Уважаемые инвесторы, мы приглашаем",
        "$100": "$100.000",
        "$400": "$400.000",
        "three_business_angels": "Трех бизнес-ангелов по",
        "venture_fund": "Венчурный фонд",
        "estimated_at_20_millions": "по оценке в $20 млн",
        "estimated_at_15_millions": "по оценке в $15 млн",

        "get_up_to_invest": "Успейте инвестировать!",
        "ceo_personally_contact_you": "Оставьте заявку и CEO лично свяжется с вами",
        "submit_your_application": "Оставить заявку",
        "contact_info_of_head": "Контактная информация основателя",
        "take_an_opportunity_to_share": "Поделитесь возможностью - отправьте другим!",

        "contact_headers": {
            "1": "+7 (777) 518-22-00",
            "2": "daukaz",
            "3": "trustme.kz",
            "4": "ceo.trustme{'@'}gmail.com",
            "5": "{'@'}qazaqeli",
            "6": "{'@'}trustme.kz",
            "7": "dauletbayev",
            "8": "dauletbayev"
        },

        "make_the_world_better": "Сделаем этот мир лучше!",
        "contacts": "Контакты",
        "politic_of_confidence": "Политика конфидециальности",
        "terms_of_use": "Пользовательское соглашение",
        "public_offer": "Публичная оферта",
        "address": "г. Астана. просп. Кабанбай Батыра 49",
        "email": "ceo.trustme{'@'}gmail.com",
        "sale_department": "Отдел продаж:+7 (707)-111-09-50",
        "tech_support": "Техподдержка: +7 (707)-412-84-12",
        "all_supported": "© 2024 TrustMe Limited. Все права защищены.",

        "invest_into_fast_sturtup": "Инвестируйте в самый быстрорастущий стартап Центральной Азии",

        "be_an_investor": "Стать инвестором",
    }
}