<template>
  <eco-system/>
</template>

<script>
import EcoSystem from "@/pages/landing/sections/EcoSystem.vue";


export default {
  name: "landingPage",
  components: {
    EcoSystem
  },
}
</script>

<style>
</style>