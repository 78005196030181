<template>
  <section class="wrapper-block">
    <div class="bg_inner_card">
      <div class="solution-grid">
        <div class="solution-item">
          <div class="solution-item__text">
          <h1 class="text-title">
            {{$t('nullPage.businessSolution')}}
          </h1>
          <p class="body1 text-description" v-html="$t('nullPage.efficiency')"></p>
        </div>
          <div class="solution-buttons">
            <button class="btn primary" @click="modalShow">
              {{$t('nullPage.contactUs')}}
            </button>
            <button @click="scrollToSection" class="btn-take_solution">
              {{$t('nullPage.takeSolution')}}
            </button>
          </div>
        </div>
        <div class="solution-item">
          <VideoCard
            :isAutoPlay="true"
            :shortUrl="main.shortUrl"
            :fullUrl="main.fullUrl"
            :poster="main.poster">
          </VideoCard>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper-block">
    <div class="inner_card">
      <div>
        <h2 class="text-center">{{ $t('nullPage.optimization') }}</h2>
      </div>
      <div class="optimization-grid">
        <div
          v-for="(card, index) in optimizations"
          :key="index"
          class="optimization-card"
        >
          <img :src="card.url" ondragstart="return false;" />
          <div class="overlay">
            <div class="text-container">
              <h3>{{ $t(`nullPage.optimizationTitle${index+1}`) }}</h3>
              <p class="body2">{{ $t(`nullPage.optimizationSubTitle${index+1}`) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper-block" id="target-section">
    <div class="bg_inner_card">
      <div>
        <h2 class="text-center text-title">{{ $t('nullPage.ourProducts') }}</h2>
      </div>
      <div class="product-grid">
        <div
          v-for="(prod, index) in products"
          :key="index"
          :class="{ 'product-card': true, 'full-width': prod.product }"
        >
          <div class="d-flex justify-content-between">
            <div class="product-card__logo">
              <div>
                <img :src="prod.icon" ondragstart="return false;" />
              </div>
              <div class="product-container">
                <h3 class="text-title">
                  {{ prod.label }}
                </h3>
                <div class="product-container__description">
                  <p
                    class="body3 text-description"
                    v-html="$t(`nullPage.productDescription${index+1}`)"
                  ></p>
                </div>
                <div class="product-button">
                  <a
                    v-if="prod.product"
                    class="btn primary"
                    href="https://trustme.kz/contract/"
                    target="_blank"
                    >{{ $t(`nullPage.productButton${index+1}`) }}</a
                  >
                  <button v-else class="btn no-active" disabled>
                    {{ $t(`nullPage.productButton${index+1}`) }}
                  </button>
                </div>
              </div>
            </div>
            <div v-if="prod.product" class="prod">
              <img :src="prod.product" ondragstart="return false;" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper-block">
    <div class="inner_card">
      <swiper
        ref="mySwiper"
        :slides-per-view="1"
        :loop="true"
        :modules="modules"
        :spaceBetween="10"
        @swiper="onSwiper"
        :navigation="{
          prevEl: prev,
          nextEl: next,
        }"
        class="mySwiper"
        :breakpoints="{
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1320: {
            slidesPerView: 3.5,
          }
        }"
      >
        <div class="d-flex justify-content-between align-items-center">
          <h2> {{ $t('nullPage.customerOpinions')}} </h2>
          <div class="d-flex">
            <div ref="prev" class="swiper-button-prev" @click="goToPrev"></div>
            <div ref="next" class="swiper-button-next" @click="goToNext"></div>
          </div>
        </div>
        <swiper-slide v-for="(card, index) in swiperCards" :key="index">
          <div class="swiperCard">
            <VideoCard :shortUrl="card.shortUrl" :fullUrl="card.fullUrl" :poster="card.poster"></VideoCard>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
  <section class="wrapper-block">
    <div class="inner_card">
      <div class="vision_block">
        <div class="vision_block__left">
          <div>
            <h2 class="text-title">{{ $t('nullPage.ourVision')}}</h2>
          </div>
          <div class="vision_block__img">
            <img src="/assets/images/stars.png" ondragstart="return false;" />
          </div>
        </div>
        <div class="vision-block-grid">
          <div
            v-for="(item, index) in visions"
            :key="index"
            :class="['vision-item', { 'vision-item_selected': item.active }]"
            @click="toggleActive(index)"
          >
            <div>
              <h3
                v-if="!item.active"
                v-html="$t(`nullPage.visionLabel${index+1}`)"
                class="text-description"
              ></h3>
              <h3 v-else v-html="$t(`nullPage.visionDescription${index+1}`)" class="description"></h3>
            </div>
            <div>
              <i
                :class="
                  item.active ? 'bi bi-arrow-up h2' : 'bi bi-arrow-down h2'
                "
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper-block">
    <div class="bg_inner_card">
      <div class="team_section__content">
        <div class="team_section__info">
          <div>
            <h2 class="text-title">{{ $t('nullPage.team') }}</h2>
          </div>
          <div class="team_section__subtitle">
            <p class="body2 text-description" v-html="$t('nullPage.teamDescription')"></p>
          </div>
          <div class="team_section__button">
            <a
              class="btn primary"
              href="https://blog.trustme.kz/career/"
              target="_blank"
              >{{ $t('nullPage.seeVacancy') }}</a
            >
          </div>
        </div>
        <div class="team-section__image_container">
          <img src="/assets/images/team1.png" ondragstart="return false;" />
        </div>
      </div>
      <div class="image-grid">
        <div
          v-for="(image, index) in teamPhotos"
          :key="index"
          :class="`grid-item item${index + 1}`"
        >
          <img :src="image.image" ondragstart="return false;" />
        </div>
      </div>
    </div>
    <div class="text-center tags">
      <div class="scroll">
        <div
          v-for="(tag, index) in tags"
          :key="`tag-${index}`"
          class="scroll-slide"
        >
          <div class="slide-tag body2 text-center">
            {{ $t(`nullPage.tag${index+1}`) }}
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper-block">
    <div class="inner_card">
      <div>
        <h2 class="text-center">{{ $t('nullPage.news') }}</h2>
      </div>
      <div class="news-grid">
        <div
          v-for="(card, index) in news"
          :key="`news-${index}`"
          class="news-card"
        >
          <div class="news-image">
            <img :src="card.image" ondragstart="return false;" />
          </div>
          <div class="news-container">
            <div class="news-container__info">
              <div class="news-container__info-item">
                <p class="body4 text-sub-description">{{ card.date }}</p>
              </div>
              <div class="news-container__info-item">
                <i class="bi bi-clock text-sub-description"></i>
                <p class="body4 text-sub-description">
                  {{ card.timeReading }}
                </p>
              </div>
              <!-- <div class="news-container__info-item">
                <i class="bi bi-eye text-sub-description"></i>
                <p class="body4 text-sub-description">
                  {{ card.views }}
                </p>
              </div> -->
            </div>
            <div class="news-container__context">
              <div class="news-container__context-title"> 
              <h4>
                <a :href="card.link" target="_blank">{{ $t(`nullPage.newsLabel${index+1}`) }}</a>
              </h4>
            </div>
              <p class="body3 text-description text-overflow">
                {{ $t(`nullPage.newsDescription${index+1}`) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <a
          href="https://blog.trustme.kz/news/"
          target="_blank"
          class="btn primary"
          >{{ $t('nullPage.seeNews') }}</a
        >
      </div>
    </div>
  </section>
  <section class="wrapper-block">
    <div class="inner_card">
      <div class="care-container">
        <img
          src="assets/images/background.png"
          class="care-container__background-image"
          ondragstart="return false;"
        />
        <div class="care-container__overlay-content">
          <h2 class="text-white">{{ $t('nullPage.trustTechnologies') }}</h2>
          <p class="body1 text-white" v-html="$t('nullPage.technologiesDescription')"></p>
          <div class="text-center">
            <button class="btn-take_solution" @click="modalShow">
              <p class="body3">{{ $t('nullPage.contactUs') }}</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import "bootstrap-icons/font/bootstrap-icons.css";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper/modules";
import VideoCard from '@/components/VideoCard.vue';
export default {
  name: "EcoSystem",
  components: { Swiper, SwiperSlide, VideoCard },
  data() {
    return {
      url: "https://demo.tct.kz/assets/video/video1.MP4",
      isModalShow: false,
      main: {
        shortUrl: 'https://demo.tct.kz/assets/video/mainShort.MOV',
        fullUrl: 'https://demo.tct.kz/assets/video/main.MP4',
        poster: '/assets/images/posters/main.png'
      },
      modules: [Navigation],
      pref: null,
      next: null,
      mySwiper: null,
      isModalVisible: false,
      optimizations: [
        {
          url: "/assets/images/optimization1.jpg",
          title: "Лучший стартап",
          description: "по версии Hero Training USA",
        },
        {
          url: "/assets/images/optimization2.jpg",
          title: "Blockchain Startup Awards",
          description: "награда в Кремниевой Долине",
        },
        {
          url: "/assets/images/optimization3.jpg",
          title: "ТОP-10 стартапов",
          description: "Центральная Азия Digital Bridge 2023",
        },
        {
          url: "/assets/images/optimization4.jpg",
          title: "В ряду лучших стартапов Центральной Азии",
          description: "согласно Google For Startups",
        },
      ],
      products: [
        {
          label: "TrustContract",
          description:
            "TrustContract – сервис мгновенного и безопасного подписания договоров через SMS, использующий смарт-контракты и удобные  шаблоны для максимальной продуктивности.",
          icon: "/assets/images/LogoContract.svg",
          product: "/assets/images/ContractProduct.svg",
          buttonLabel: "Перейти",
          active: true,
          link: "https://trustme.kz/contract/",
        },
        {
          label: "TrustAccounting",
          description:
            "Сервис для автоматизации и цифровизации первичной учетной  документации, с оптимизацией создания, подписания  и хранения документов.",
          icon: "/assets/images/LogoAccounting.svg",
          buttonLabel: "Coming Soon",
          active: false,
        },
        {
          label: "TrustHR",
          description:
            "Сервис для автоматизации кадровых процессов, упрощения  подачи и утверждения заявлений, и повышения эффективности  HR отдела.",
          icon: "/assets/images/LogoHR.svg",
          buttonLabel: "Coming Soon",
          active: false,
        },
      ],
      visions: [
        {
          label: "Миссия",
          description: "Делать мир лучше, с помощью технологий и инноваций",
          active: false,
        },
        {
          label: "Цель",
          description: "Стать первым единорогом в Центральной Азии",
          active: false,
        },
        {
          label: "Ценности",
          description:
            "Чистота доверия и открытость во всех аспектах нашей деятельности",
          active: false,
        },
      ],
      swiperCards: [
        {
          shortUrl: "https://demo.tct.kz/assets/video/video1.mp4",
          fullUrl: "https://demo.tct.kz/assets/video/client1.MP4",
          poster: '/assets/images/posters/1.png'
        },
        {
          shortUrl: "https://demo.tct.kz/assets/video/video2.mp4",
          fullUrl: "https://demo.tct.kz/assets/video/client2.MP4",
          poster: '/assets/images/posters/2.png'
        },
        {
          shortUrl: "https://demo.tct.kz/assets/video/video6.mp4",
          fullUrl: "https://demo.tct.kz/assets/video/client6.MP4",
          poster: '/assets/images/posters/6.png'
        },
        {
          shortUrl: "https://demo.tct.kz/assets/video/video3.mp4",
          fullUrl: "https://demo.tct.kz/assets/video/client3.MP4",
          poster: '/assets/images/posters/3.png'
        },
        {
          shortUrl: "https://demo.tct.kz/assets/video/video4.mp4",
          fullUrl: "https://demo.tct.kz/assets/video/client4.MOV",
          poster: '/assets/images/posters/4.png'
        },
        {
          shortUrl: "https://demo.tct.kz/assets/video/video5.mp4",
          fullUrl: "https://demo.tct.kz/assets/video/client5.MP4",
          poster: '/assets/images/posters/5.png'
        }
      ],
      teamPhotos: [
        { image: "/assets/images/team2.png" },
        { image: "/assets/images/team3.png" },
        { image: "/assets/images/team4.png" },
        { image: "/assets/images/team5.png" },
      ],
      tags: [
        "эффективность",
        "результативность",
        "клиентоориентированность",
        "профессионализм",
        "эффективность",
        "результативность",
        "клиентоориентированность",
        "профессионализм",
      ],
    };
  },
  computed: {
    news(){
      return [
        {
          label: "White Paper для I этапа Seed инвестиций",
          date: "31.01.2023",
          views: "434",
          timeReading: `${ this.$t('nullPage.time',{time: "15"} ) }`,
          image: "/assets/images/news1.png",
          link: "https://blog.trustme.kz/news/white-paper-2023seed-investment/",
          description:
            "Здравствуйте, друзья! Сегодня я хотел бы рассказать вам о человеке, который сделал невозможное возможным - Чингизе Даулетбаеве, основателе и генеральном директоре компании TrustMe.",
        },
        {
          label: "Обновления TrustContract 2024",
          date: "31.01.2023",
          views: "434",
          timeReading: `${ this.$t('nullPage.time',{time: "15"} ) }`,
          image: "/assets/images/news2.png",
          link: "https://blog.trustme.kz/news/obnovleniya-trustcontract/",
          description:
            "Дорогие друзья, мы подготовили несколько крутых обновлений сервиса TrustContract, которые точно понравятся вам. В этом материале попробуем описать их.",
        },
        {
          label: "ТОП-5 часто задаваемых вопросов о Trust Contract",
          date: "30.01.2023",
          views: "689",
          timeReading: `${ this.$t('nullPage.time',{time: "12"} ) }`,
          image: "/assets/images/news3.png",
          link: "https://blog.trustme.kz/news/top-5-chasto-zadavaemyh-voprosov/",
          description:
            "В этом материале мы подготовили для вас ТОП-5 часто задаваемых вопросов пользователей о TrustContract.",
        },
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.pref = this.$refs.prev;
      this.next = this.$refs.next;

      this.$nextTick(() => {
        if (this.mySwiper && this.mySwiper.swiper) {
          const swiperInstance = this.mySwiper.swiper;
          swiperInstance.params.navigation.prevEl = this.pref;
          swiperInstance.params.navigation.nextEl = this.next;
          swiperInstance.navigation.init();
          swiperInstance.navigation.update();
        }
      });
    });
  },
  methods: {
    scrollToSection() {
      const section = document.getElementById("target-section");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    },
    onSwiper(swiper) {
      this.mySwiper = swiper;
    },
    goToPrev() {
      if (this.mySwiper) {
        this.mySwiper.slidePrev();
      }
    },
    goToNext() {
      if (this.mySwiper) {
        this.mySwiper.slideNext();
      }
    },
    toggleActive(index) {
      this.visions[index].active = !this.visions[index].active;
    },
    modalShow() {
      this.$store.commit("main/setModalShow", true);
    },
  },
};
</script>

<style lang="scss">
.wrapper-block {
  width: 100%;
  box-sizing: border-box;
  margin-top: 140px;
  padding: 0px 16px ;
}
.wrapper-block:first-of-type {
  margin-top: 0;
  padding: 16px 18px 0px 16px;
}
.wrapper-block:last-of-type {
  margin-bottom: 140px;
}



.bg_inner_card {
  display: flex;
  flex-direction: column;
  gap: 40px;
  border-radius: 40px;
  padding: 80px 62px;
  max-width: 1300px;
  background: white;
  margin: 0 auto;
}

.solution-grid {
  display: grid;
  grid-template-columns: 2.25fr 1fr;
  gap: 37px;
  align-self: stretch;
  align-items: center;
}

.solution-video {
  border-radius: 10px;
}

.solution-buttons {
  display: flex;
  gap: 24px;
  margin-top: 40px;
}

.solution-item {
  position: relative;
  .solution-item__text{
    display: flex;
    flex-direction: column;
    gap:20px;
    p, h1 {
      margin: 0px;
    }
  }
}

.solution-image {
  display: block;
  width: 100%;
  height: auto;
}

.btn-take_solution {
  background: white;
  color: #46a3a3;
  border: 1px solid #46a3a3;
  border-radius: 8px;
  padding: 12px 24px;
  min-width: 184px;
  min-height: 48px;
}

.optimization-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.optimization-card {
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  height: 200px;
}

.optimization-card img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This ensures the image covers the container without distortion */
  border-radius: 20px;
}

.optimization-card .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.optimization-card .text-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap:4px;
  bottom: 20px;
  left: 20px;
  color: white;
  max-width: 470px;
  text-align: left;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-gap: 20px;
  grid-template-areas:
    "full full"
    "half1 half2";
}

.product-card {
  padding: 40px 20px;
  border-radius: 20px;
  background: #f3f3f4;
}

.product-container {
  display: flex;
  max-width: 525px;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
  word-wrap: break-word;
}
.product-container__description {
  min-height: 66px;
}
.product-button {
  margin-top: 16px;
}

.full-width {
  grid-area: full;
}

.product-card:nth-child(2) {
  grid-area: half1;
}

.product-card:nth-child(3) {
  grid-area: half2;
}

.product_description {
  color: #5a7676;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
}

.swiper {
  margin-top: 50px;
  margin-left: auto;
  gap: 40px;
  margin-right: auto;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  display: block;
  display: flex;
  flex-direction: column-reverse;
}

.swiper-vertical > .swiper-wrapper {
  flex-direction: column;
}

.swiper-wrapper {
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-horizontal {
  touch-action: pan-y;
}

.swiper-vertical {
  touch-action: pan-x;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
  display: block;
}

/* CSS Mode */
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev.swiper-button-hidden,
.swiper-button-next.swiper-button-hidden {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}

.swiper-lazy-preloader-white {
  --swiper-preloader-color: #fff;
}

.swiper-lazy-preloader-black {
  --swiper-preloader-color: #000;
}

@keyframes swiper-preloader-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.swiper-button-prev,
.swiper-button-next {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.swiper-button-prev::before,
.swiper-button-next::before {
  content: "";
  display: block;
  color: black;
  width: 15px;
  height: 15px;
  border-top: 2px solid black;
  border-left: 2px solid black;
  position: absolute;
}

.swiper-button-prev::before {
  transform: rotate(-45deg);
  left: 15px;
}

.swiper-button-next::before {
  transform: rotate(135deg);
  right: 15px;
}

.swiper-button-prev,
.swiper-button-next::after {
  font-size: 30px;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
}

.swiper-button-prev::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.swiper-button-next::after {
  content: "";
  position: absolute;
  right: 50%;
  transform: translateX(50%);
}

.swiper-rtl .swiper-button-next::before {
  transform: rotate(-95deg);
}

.swiper-rtl .swiper-button-prev::before {
  transform: rotate(45deg);
}

.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiperCard {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f3;
  border-radius: 20px;
  img {
    max-width: 100%;
    border-radius: 10px;
  }
}

.vision-block-grid {
  width: 60%;
  display: grid;
  grid-gap: 20px;
  grid-template-rows: auto;
}

.vision_block {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.vision_block__left{
  max-width: 30%;
}
.vision_block__img {
  display: block;
  margin-top: 46px;
  img{
    width: 100%;
  }
}

.vision-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 28px;
  padding: 40px 32px;
  background: white;
}

.vision-item:not(.vision-item_selected):hover {
  background: #cbdcdc;
  color: #203939;
}

.vision-item_selected {
  background: #46a3a3;
  color: white;
}

.team_section__content {
  display: flex;
  gap: 20px;
  .team_section__info {
    width: 60%;
    .team_section__subtitle {
      margin-top: 16px;
    }
    .team_section__button {
      margin-top: 32px;
    }
  }
  .team-section__image_container {
    width: 40%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
  }
}

.image-grid {
  display: grid;
  row-gap: 20px;
  column-gap: 20px;
  grid-template-areas:
    "item1 item2"
    "item3 item4";

  .grid-item {
    border-radius: 12px;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .item1 {
    grid-area: item1;
    grid-column: span 1;
  }

  .item2 {
    grid-area: item2;
    grid-column: span 2;
  }

  .item3 {
    grid-area: item3;
    grid-column: span 2;
  }

  .item4 {
    grid-area: item4;
    grid-column: span 1;
  }
}

.news-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  .news-card {
    padding: 32px 20px;
    background: white;
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 28px;
    .news-image img {
      width: 100%;
    }
    .news-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .news-container__context{
          display: flex;
          flex-direction: column;
          gap:8px;
        }
      .news-container__info {
        display: flex;
        align-items: center;
        gap: 24px;
        .news-container__info-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          p {
            margin:0px;
          }
        }
      }
    }
  }
}

.tags {
  padding: 20px;
  display: flex;
  align-items: center;
  margin-top: 40px;
  height: auto;
}

.scroll {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  -webkit-mask-image: linear-gradient(
    90deg,
    transparent,
    #fff 20%,
    #fff 80%,
    transparent
  );
  .scroll-slide {
    display: inline-block;
  }

  .scroll-slide .slide-tag {
    border: 2px solid #46a3a3;
    color: #46a3a3;
    background : white;
    display: flex;
    align-content: center;
    justify-content: center;
    border-radius: 20px;
    padding: 10px 20px;
    margin: 0 40px;
  }
}

.scroll .scroll-slide {
  animation: scroll 12s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(40%);
  }

  to {
    transform: translateX(-40%);
  }
}

.care-container {
  position: relative;
}

.care-container__background-image {
  width: 100%;
  height: auto;
  display: block;
  max-width: 100%;
}

.care-container__overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  .btn-take_solution{
    margin-top:16px;
  }
}
@media (min-width: 768px) and (max-width: 1000px) {
  .news-card:nth-child(3) {
    display: none;
  }
  .vision-item {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .wrapper-block {
    margin-top: 60px;
    .bg_inner_card {
      padding: 40px 20px;
      gap: 24px;
      border-radius: 20px;
  }
    .inner_card{
      gap: 24px;
    }
  }
  .vision_block__left{
    max-width: 100%;
  }
  .optimization-grid {
    grid-template-columns: 1fr;
    text-align: center;

    .optimization-card {
      .text-container{
        left:28px;
      } 
    img {
    width: 100%;
    height: auto;
  }
}
  }



  .news-grid {
    grid-template-columns: 1fr;

    .news-card,
    .news-container {
      text-align: center;
      width: 100%;
    }
    .news-container__info {
      margin: auto;
    }
  }

  .image-grid {
    grid-template-columns: 1fr;
    grid-template-areas:
      "item1"
      "item2"
      "item3"
      "item4";
      .item1,
      .item2,
      .item3,
      .item4 {
        width: 100%;
        height: auto;
        grid-column: span 1;
      }
  }

  .product-grid {
    grid-template-columns: 1fr;
    grid-template-areas:
      "full"
      "half1"
      "half2";
  }

  .product-card {
    border-radius: 12px;
    padding: 24px 28px;
    text-align: center;
    .product-container{
      max-width: 100%;
      gap: 12px;
      .product-button{
        margin-top:20px;
      }
    }
  }

  .prod {
    display: none;
  }
  .scroll-slide .slide-tag {
    margin: 0 10px;
  }
  .solution-item__text{
    gap: 12px;
  }
  .solution-grid {
    grid-template-columns: 1fr;
    row-gap: 40px;
    text-align: center;

    .solution-buttons {
      flex-direction: column;
      gap: 12px;
      width: 100%;
      margin-top: 24px;
    }

    .solution-item img {
      width: 100%;
    }
  }

  .image-grid {
    grid-template-columns: 1fr;
  }

  .vision_block {
    text-align: center;
    flex-direction: column;
  }

  .vision_block__img {
    display: none;
  }
  .vision-block-grid {
    margin-top: 24px;
    width: 100%;
  }

  .vision-item {
    padding: 20px;
    border-radius: 16px;
  }

  .team_section__content {
    flex-direction: column;
    text-align: center;
    gap: 24px;

    .team_section__info {
      width: 100%;
    }
    .team-section__image_container {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .care-container__background-image {
    min-height: 361px;
    .btn-take_solution{
    margin-top:12px;
  }
  }
}
</style>