<template>
   <div class="video-card" @mouseover="playVideo" @mouseleave="pauseVideo" @click="playFullVideo">
    <video :key="shortUrl+fullUrl" ref="videoPlayer" :src="shortUrl" muted loop  :autoplay="isAutoPlay" playsinline controlsList="nodownload" :poster="poster"></video>
  </div>
</template>

<script>
export default {
  name: "VideoCard",
  props: {
    shortUrl: {
      type: String,
      required: true,
    },
    fullUrl: {
      type: String,
      required: true,
    },
    poster: {
      type: String,
    },
    isAutoPlay: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      currentSrc: '',
      isHovered: false,
      touchTimer: null,
    };
  },
  methods: {
    playVideo() {
      const video = this.$refs.videoPlayer;
      video.play().catch(error => {
        console.error('Error playing video:', error);
      });
    },
    pauseVideo() {
      const video = this.$refs.videoPlayer;
      if (video) {
        video.pause();
      }
    },
    playFullVideo(event) {
      const video = this.$refs.videoPlayer;
      event.stopPropagation();
      video.src = this.fullUrl;
      video.loop = false;
      video.muted = false;
      this.$nextTick(() => {
        video.play().then(() => {
          this.enterFullScreen(video);
        }).catch(error => {
          console.error('Error playing full video:', error);
        });
      });
    },
    enterFullScreen(video) {
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen();
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen();
      } else if (video.msRequestFullscreen) {
        video.msRequestFullscreen();
      } else if (video.webkitEnterFullscreen) { // Добавлено для iPhone
        video.webkitEnterFullscreen();
      }
    }
  }
};
</script>
<style scoped>
.video-card {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

video {
  max-width: 100%;
  max-height: 570px;
  border-radius: 20px;
  object-fit: cover;
}

video:fullscreen {
  object-fit: contain;
  width: 100vw;
  height: auto;
  background: black;
}

video:-webkit-full-screen {
  object-fit: contain;
  width: 100vw;
  height: auto;
  background: black;
}

video:-moz-full-screen {
  object-fit: contain;
  width: 100vw;
  height: auto;
  background: black;
}

video:-ms-fullscreen {
  object-fit: contain;
  width: 100vw;
  height: auto;
  background: black;
}
</style>
