export default {
    "businessSolution":"Создаем экосистемные IT решения для бизнеса",
    "efficiency":"Цифровизация бизнес-процессов для повышения<br />эффективности и прозрачности в вашей компании",
    "takeSolution":"Подобрать решение",
    "optimization":"TrustMe — IT-решение по оптимизации бизнеса",
    "optimizationTitle1":"Лучший стартап",
    "optimizationSubTitle1": "по версии Hero Training USA",
    "optimizationTitle2":"Blockchain Startup Awards",
    "optimizationSubTitle2": "награда в Кремниевой Долине",
    "optimizationTitle3":"ТОP-10 стартапов",
    "optimizationSubTitle3": "Центральная Азия Digital Bridge 2023",
    "optimizationTitle4":"В ряду лучших стартапов Центральной Азии",
    "optimizationSubTitle4": "согласно Google For Startups",
    "ourProducts":"Наши продукты",
    "productDescription1": "TrustContract – сервис мгновенного и безопасного подписания договоров через SMS, использующий смарт-контракты и удобные  шаблоны для максимальной продуктивности.",
    "productButton1": "Перейти",
    "productDescription2": "Сервис для автоматизации и цифровизации первичной учетной  документации, с оптимизацией создания, подписания  и хранения документов.",
    "productButton2": "Скоро",
    "productDescription3": "Сервис для автоматизации кадровых процессов, упрощения  подачи и утверждения заявлений, и повышения эффективности  HR отдела.",
    "productButton3": "Скоро",
    "customerOpinions":"Что говорят клиенты",
    "ourVision" : "Наше видение",
    "visionLabel1": "Миссия",
    "visionDescription1": "Делать мир лучше, с помощью технологий и инноваций",
    "visionLabel2": "Цель",
    "visionDescription2": "Стать первым единорогом в Центральной Азии",
    "visionLabel3": "Ценности",
    "visionDescription3": "Чистота доверия и открытость во всех аспектах нашей деятельности",
    "team" : "Команда",
    "teamDescription": "В основе нашей работы лежат чистота, довериеи открытость во всех аспектах нашей деятельности. Присоединяйтесь к нам!",
    "seeVacancy": "Смотреть вакансии",
    "tag1": "эффективность",
    "tag2": "результативность",
    "tag3": "клиентоориентированность",
    "tag4": "профессионализм",
    "tag5": "эффективность",
    "tag6": "результативность",
    "tag7": "клиентоориентированность",
    "tag8": "профессионализм",
    "news": "Новости",
    "newsLabel1": "White Paper для I этапа Seed инвестиций",
    "newsDescription1": "Здравствуйте, друзья! Сегодня я хотел бы рассказать вам о человеке, который сделал невозможное возможным - Чингизе Даулетбаеве, основателе и генеральном директоре компании TrustMe.",
    "newsLabel2": "Обновления TrustContract 2024",
    "newsDescription2": "Дорогие друзья, мы подготовили несколько крутых обновлений сервиса TrustContract, которые точно понравятся вам. В этом материале попробуем описать их.",
    "newsLabel3": "ТОП-5 часто задаваемых вопросов о Trust Contract",
    "newsDescription3": "В этом материале мы подготовили для вас ТОП-5 часто задаваемых вопросов пользователей о TrustContract.",
    "seeNews": "Смотреть другие",
    "trustTechnologies": "Технологии, которым можно доверять",
    "technologiesDescription":"Наши технологии созданы профессионалами для профессионалов<br />с максимальной заботой о клиентах и их потребностях.",
    "contactUs":"Связаться с нами",
    "time":"{time} минут",
    }