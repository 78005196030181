import { createApp } from "vue";
import App from "./App.vue";
import i18n from "./i18n";
import store from "./store";
import NotificationContainer from '@/components/NotificationContainer.vue';


const app = createApp(App);

app.use(i18n)
  .provide("$i18n", i18n)
  .use(store)
  .provide("$store", store);


app.config.globalProperties.$notify = function (notification) {
  this.$root.$emit('notify', notification);
};

app.component('NotificationContainer', NotificationContainer);

app.mount("#app");
