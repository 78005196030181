<template>
    <transition name="fade">
      <div v-if="visible" :class="['m-notification', typeClass]" @click="close">
        <div class="m-notification__content">
          <slot>
            <span>{{ message }}</span>
          </slot>
        </div>
        <button class="m-notification__close" @click.stop="close">
          <i class="bi bi-close">close</i>
        </button>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    name: "NotificationVue",
    props: {
      message: {
        type: String,
        required: true
      },
      type: {
        type: String,
        default: "info"
      },
      duration: {
        type: Number,
        default: 3000
      }
    },
    data() {
      return {
        visible: true
      };
    },
    computed: {
      typeClass() {
        return `m-notification--${this.type}`;
      }
    },
    mounted() {
      setTimeout(this.close, this.duration);
    },
    methods: {
      close() {
        this.visible = false;
        this.$emit("close");
      }
    }
  };
  </script>
  
  <style scoped>
  .m-notification {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    margin: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: white;
    cursor: pointer;
  }
  
  .m-notification__content {
    flex: 1;
  }
  
  .m-notification__close {
    background: none;
    border: none;
    font-size: 16px;
    color: white;
    cursor: pointer;
  }
  
  .m-notification--info {
    background-color: #2196f3;
  }
  
  .m-notification--success {
    background-color: #4caf50;
  }
  
  .m-notification--warning {
    background-color: #ff9800;
  }
  
  .m-notification--error {
    background-color: #f44336;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>