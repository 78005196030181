<template>
    <div class="notifications">
      <Notification
        v-for="(notification, index) in notifications"
        :key="index"
        :message="notification.message"
        :type="notification.type"
        :duration="notification.duration"
        @close="removeNotification(index)"
      />
    </div>
  </template>
  
  <script>
  import Notification from './Notification.vue';
  
  export default {
    name: 'NotificationContainer',
    components: {
      Notification
    },
    data() {
      return {
        notifications: []
      };
    },
    methods: {
      addNotification(notification) {
        this.notifications.push(notification);
      },
      removeNotification(index) {
        this.notifications.splice(index, 1);
      }
    },
    created() {
      this.$root.$on('notify', this.addNotification);
    },
    beforeUnmount() {
      this.$root.$off('notify', this.addNotification);
    }
  };
  </script>
  
  <style>
  .notifications {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  </style>