<template>
  <footer class="wrapper-footer d-flex flex-column justify-content-between">
    <div class="container m-auto">
      <div class="d-flex justify-content-between align-items-top first-container">
        <div class="footer_logo_container">
          <div class="footer_logo__desktop">
            <img src="/assets/images/Logo.png" alt="">
          </div>
          <div class="footer_logo__mobile">
            <img src="/assets/images/footer-logo.svg"/>
          </div>
          <p class="body3 text-center text-white">{{$t("landing.make_the_world_better")}}</p>
        </div>
        <div class="footer_second_container">
          <h3 class="text-white">{{$t("landing.services")}}</h3>
          <button @click="scrollToSection"><p class="mt-3 text-white">{{$t("header.products")}}</p></button>
          <a href="https://blog.trustme.kz/career"><p class="mt-3 text-white">{{$t("header.career")}}</p></a>
          <a href="https://blog.trustme.kz/news"><p class="mt-3 text-white">{{$t("header.news")}}</p></a>
          <a href="https://blog.trustme.kz/news_categories/blog-osnovatelya/"><p class="mt-3 text-white">{{$t("header.blog")}}</p></a>
        </div>
        <div class="footer_third_container">
          <h3 class="text-white">{{$t("landing.contacts")}}</h3>
          <div v-for="(contact,index) in contactList" :key="`contact-${index}`" 
          class="d-flex gap-15 align-items-center">
          <a :href="contact.link" class="footer_third_container__link">
          <img :src="contact.icon" alt="">
            <p class="pt-3 text-white">{{$t(contact.label)}}</p>
          </a>
        </div>
        </div>
        <div class="footer_four_container">
          <div v-for="(contract,index) in contractList" :key="`contract-${index}`">
            <a :href="contract.link" target="_blank">
              <p class="text-white">{{$t(contract.label)}}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="container m-auto">
      <div class="d-flex justify-content-between align-items-center last-container">
        <p class="pt-3 text-white">{{$t("landing.all_supported")}}</p>
        <div class="d-flex gap-15 align-items-center">
          <div v-for="(media, index) in socialMedias" :key="`media-${index}`">
            <a :href="media.link" target="_blank">
              <img :src="media.src" alt=""/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
  methods: {
    scrollToSection() {
      const section = document.getElementById("target-section");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  data(){
    return {
      contractList: [
        {label:"landing.politic_of_confidence", link:"https://trustme.kz/contract/privacypolicy"},
        {label:"landing.terms_of_use", link:"https://trustme.kz/contract/termofuse"},
        {label:"landing.public_offer", link:"https://trustme.kz/contract/serviceAcceptablePolicy"},
      ],
      contactList: [
        {icon:'/assets/icons/footer-icon1.svg', label:'landing.address', link:"https://2gis.kz/astana/firm/70000001057267140?m=71.405315%2C51.102645%2F16"},
        {icon:'/assets/icons/footer-icon2.svg', label:'landing.email', link:"mailto:ceo.trustme@gmail.com"},
        {icon:'/assets/icons/footer-icon3.svg', label:'landing.sale_department', link:"tel:+77071110950"},
        {icon:'/assets/icons/footer-icon4.svg', label:'landing.tech_support', link:"tel:+77074128412"},
      ],
      socialMedias: [
        {src: '/assets/icons/facebook.svg', link: 'https://www.facebook.com/trustmekz'},
        {src: '/assets/icons/instagram.svg', link: 'https://www.instagram.com/trustme.kz/'},
        {src: '/assets/icons/tik-tok.svg', link: 'https://www.tiktok.com/@trustmekz'},
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper-footer {
  width: 100%;
  min-height: 450px;
  background-color: #2A2B2C;
  
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .footer_logo_container {
    display: flex;
    width: 100%;
    align-content: center;
    flex-direction: column;
    gap: 16px;
    .footer_logo__mobile{
      display: none;
    }
    .footer_logo__desktop{
      display: block;
    }
    
    img {
      width: 87px;
      height: 20px;
    }
    width: 200px;
    height: 70px;
  }
  .footer_second_container {
    width: 100px;
    button{
      border: none;
      background: none;
      padding: 0px;
      p{
        margin:0px;
      }
    }
  }
  .footer_third_container {
    width: 306px;
    .footer_third_container__link{
      display: flex;
      flex-direction: row;
      gap:12px;

    }
  }
  .footer_four_container {
    width: 222px;
    height: 100px;
  }
}

@media only screen and (max-width: 992px) {
  .wrapper-footer {
    .footer_logo_container {
      width: 180px;
    }
    .footer_second_container {
      width: 60px;
    }
    .footer_third_container {
      width: 206px;
    }
    .footer_four_container {
      width: 122px;
      height: 100px;
    }
    .last-container {
      margin-top: 80px;
    }
  }
  .gap-15 {
    gap: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .wrapper-footer {
    height: 100%;
    .first-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      text-align: center;
    }

    .footer_logo_container {
      margin-top: 30px;
      width: 180px;
      height: 70px;
      .footer_logo__mobile{
      display: flex;
      img{
        width: 100%;
        height: 100%;
        margin: auto;
      }
      }
      .footer_logo__desktop{
        display: none;
      }
    }
    .footer_second_container {
      border-top: 1px solid dimgray;
      margin-top: 10px;
      padding-top: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .footer_third_container {
      text-align: center;
      margin-top: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      div {
        display: flex;
        align-items: center;
      }
    }
    .footer_four_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 60px;
      margin-top:20px;
    }
    .last-container {
      border-top: 1px solid dimgray;
      padding-top: 10px;
      padding-bottom: 20px;
      margin-top: 80px;
      gap: 5px;
      display:flex;
      flex-direction: column;
    }
  }
  .gap-15 {
    gap: 5px;
  }
}
.gap-15 {
  gap: 15px;
}
</style>