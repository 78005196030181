<template>
    <div class="vue-tel-input__wrapper">
        <vue-tel-input
            v-model="phoneNumber"
            autoFormat
            valid-characters-only
            :auto-default-country="false"
            :default-country="getDefaultCountry"
            mode="international"
            :dropdownOptions="{
                showSearchBox: true,
                showFlags: true,
                showDialCodeInSelection: true,
                showDialCodeInList: true,
                showDialCodeInSelection: false,
            }"
            :inputOptions="{
                placeholder: placeholder || $t('contactUs.enterPhoneNumber', {dial: getDial}),
                readonly: readonly,
                showDialCode: false,
                name: 'Phone'
            }"
            :disabled="readonly"
            @on-input="getPhoneNumber"
            @blur="handleValue"
            @enter="handleValue"
            @country-changed="handleValue"
        />
        <span v-if="errorVisible && !isValidNumberLength" class="inputError">
            {{ $t('contactUs.numberNotValid') }}
        </span>
    </div>
</template>
<script>
import { VueTelInput } from 'vue-tel-input';
import "vue-tel-input/vue-tel-input.css";

export default {
    name: 'VuePhone',
    components: {VueTelInput},
    props: {
        phone: [String, Number],
        validationMethod: Function, // accepts one parameter @PhoneNumber object
        showError: {
            type: Boolean,
            default: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        placeholder: String
    },
    data() {
        return {
            phoneNumberObject: null,
            phoneNumber: '',
        };
    },

    mounted() {
        if (this.phone) {
            this.phoneNumber = `+${this.phone.replace('+', '')}`;
        }
    },

    watch: {
        phone: function(newValue, oldValue) {
            if(newValue !== oldValue) {
                this.phoneNumber = newValue;
            }
        },
        phoneNumber: function(newValue) {
            if (!newValue) {
                this.emptyEmiting();
            }
            this.handleValue();
        },
    },

    computed: {
        getDial() {
            return this.phoneNumberObject?.country?.dialCode || '7';
        },
        notHaveNumber() {
            if (this.phone) {
                return false;
            }
            return true;
        },
        nationalNumber() {
            return this.phoneNumberObject?.nationalNumber || '';
        },
        errorVisible() {
            return this.showError && !this.readonly && this.phoneNumber;
        },
        isValidNumberLength() {
            return this.nationalNumber.length >= 10 && this.nationalNumber.length < 15;
        },
        isValid() {
            return this.phoneNumberObject && this.phoneNumberObject.valid;
        },
        getDefaultCountry() {
            // @TODO нужно добавлять новое
            return 'kz';
        }
    },

    methods: {
        handleValue() {
            if (this.phoneNumber && this.phoneNumberObject?.number) {
                const notValid = !this.isValid && !this.isValidNumberLength && !this.readonly;

                // the library can not valid it
                if (this.isValid && !this.readonly) {
                    this.$emit('isValid', this.phoneNumberObject?.valid)
                    this.$emit('updatePhone', this.phoneNumberObject.number.replace('+', ''));
                }

                // too short or too long
                if (this.isValidNumberLength && !this.readonly) {
                    this.$emit('updatePhone', this.phoneNumberObject?.number.replace('+', ''));
                }

                // not valid at all
                if (notValid) {
                    this.emptyEmiting();
                }
            }
        },
        getPhoneNumber(phone, phoneNumberObject) {
            this.phoneNumberObject = phoneNumberObject;
            console.log(phoneNumberObject)
        },
        emptyEmiting() {
            this.$emit('isValid', false)
            this.$emit('updatePhone', '');
        },
        emptyLocalVariable() {
            this.phoneNumberObject = null;
            this.$nextTick(() => {
                if (this.phoneNumber.length) this.phoneNumber = '';
            });
        },
    },
}
</script>

<style></style>
<style lang="scss" scoped>
    .vue-tel-input__wrapper {
  :deep(.vue-tel-input) {
    border: 1px solid #E6EAED;
    :not(.disabled) {
      &:hover {
        border: 1px solid #1d1d1d;
      }
    }
    &.disabled {
      &:hover {
        border: 1px dashed #1d1d1d;
      }
    }
  }
  :deep(.disabled), :deep([disabled]) {
    opacity: 1 !important;
    border: 1px solid #eef4f4;
    border-radius: 8px;
    background: #fafcfc;
    & > .vti__dropdown {
      display: none;
    }
  }
  :deep(.vti__input) {
    font-family: 'Roboto-Regular';
    font-size: 16px;
    padding: 12px 16px;
    line-height: 1.4em;
    background: #fafcfc;
    border-radius: 6px;
    order: 1;
  }
  :deep(.vti__input input::placeholder) {
    font-weight: 400;
    line-height: 120%;
    background: #fafcfc;
    font-family: 'Roboto';
  }
  :deep(.vti__dropdown) {
    order: 2;
  }
  :deep(.vti__dropdown-list) {
    min-width: 320px;
    overflow-x: hidden;
    left: unset;
    right: 0;
  }
  :deep(.vti__search_box) {
    width: calc(100% - 4px);
  }
  .inputError, .inputWarning {
            display: block;
            margin-top: 10px;
            margin-left: 16px;
            font-size: 12px;
        }
        .inputError {
            color: #dc3545;
        }
        .inputWarning {
            color: #dc7835;
        }
}

</style>
